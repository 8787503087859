import React, { useEffect, useRef, useState } from "react";
import professional from "../../assets/img/professional.png";
import users from "../../assets/img/user.png";
import { Pause, Play } from "lucide-react";

const benefitsData = [
  {
    imageSrc: users,
    title: "For Users",
    description: (
      <>
        <span className="font-bold">HandyPros</span> upholds high standards of
        service, punctuality, and professionalism for every professional
      </>
    ),
  },
  {
    imageSrc: professional,
    title: "For Professionals",
    description: (
      <>
        <span className="font-bold">HandyPros</span> equips each professional
        with branded kits, and professional support to ensure that they can
        represent themselves and the platform with pride and confidence
      </>
    ),
  },
];

function SweetBenefits() {
  const [isVisible, setIsVisible] = useState(false);
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    const currentElement = document.getElementById("youtube-player");
    if (currentElement) {
      observer.observe(currentElement);
    }

    setVideoId(getIdVideo(`https://youtube.com/embed/qX8`));
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const getIdVideo = (url) => {
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);
    return searchParams.get("v");
  };
  return (
    <section className="flex flex-col mt-24 max-w-full rounded-none w-full max-md:mt-10 lg:px-16 px-4" data-aos="zoom-in-up">
      <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Learn more about Us
            </h2>
            <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">
                Know more about how we operate.
            </p>
          </div>
      {/* <header className="flex flex-wrap gap-5 justify-between w-full max-md:max-w-full">
        <div className="flex flex-wrap gap-10 my-auto max-md:max-w-full">
          <h1 className="flex-auto text-5xl font-semibold text-stone-900 max-md:text-4xl">
            Sweet Benefits
          </h1>
          <p className="flex-auto text-lg font-medium leading-8 text-stone-600">
            We offer a diverse array of bespoke services, all delivered by
            qualified and trusted professionals.
          </p>
        </div>
        <button className="px-8 py-5 text-lg font-medium leading-loose text-white bg-neutral-700 rounded-[70px] max-md:px-5">
          Get Started
        </button>
      </header>
      <div className="self-center mt-28 max-w-full w-[80%] max-md:mt-10">
        <div className="flex gap-5 max-md:flex-col">
          {benefitsData.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </div>
      </div> */}
      <div className="relative w-full max-w-4xl mx-auto my-8 overflow-hidden shadow-2xl rounded-3xl">
        <div className="aspect-w-16 aspect-h-4xl">
          <iframe
            id="youtube-player"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&loop=1&playlist=${videoId}&showinfo=0&rel=0&modestbranding=1${
              isVisible ? "&autoplay=1" : ""
            }`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full h-96 object-cover"
          ></iframe>
        </div>
        <div className="absolute inset-0 bg-gradient-to-br from-purple-500/50 to-pink-500/50 mix-blend-overlay"></div>
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 text-white">
          <h2 className="text-2xl font-bold mb-2">HandyPros</h2>
          <p className="text-sm">Watch this video and learn more about us!</p>
        </div>
      </div>
    </section>
  );
}

function BenefitCard({ imageSrc, title, description }) {
  return (
    <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col max-md:mt-10">
        <img
          loading="lazy"
          src={imageSrc}
          alt=""
          className="object-contain w-full rounded-3xl aspect-[0.85]"
        />
        <h2 className="self-start mt-8 text-3xl font-semibold leading-none text-stone-900">
          {title}
        </h2>
        <p className="mt-5 mr-6 text-base font-medium leading-8 text-stone-600 max-md:mr-2.5">
          {description}
        </p>
      </div>
    </article>
  );
}

export default SweetBenefits;
