import React from "react";
import whatwd from "../../assets/img/whatwd.png";

const coreValues = [
  {
    title: "Professionalism",
    description:
      "Every service provider on HandyPros is trained to deliver the highest standards of service.",
  },
  {
    title: "Reliability",
    description:
      "We ensure that services are delivered promptly and efficiently, without compromising on quality.",
  },
  {
    title: "Trust",
    description:
      "Our verification process ensures that every professional is vetted and trustworthy",
  },
  {
    title: "Customer-Centricity",
    description:
      "We are committed to exceeding customer expectations in every interaction.",
  },
  {
    title: "Empowerment",
    description:
      "HandyPros is dedicated to empowering professionals by providing them with opportunities to grow, improve, and earn more.",
  },
];

function CoreValues() {
  return (
    <section className="flex flex-wrap gap-10 justify-center items-center mt-24 max-w-full text-stone-900 w-full max-md:mt-10 py-10 lg:px-16 px-4">
      <div className="flex flex-col grow shrink items-start self-stretch my-auto text-base font-medium text-stone-900 w-full lg:w-[42%]">
        <h2 className="text-4xl font-bold">Our Core Values</h2>
        <p className="self-stretch mt-8 leading-8">
          <strong>HandyPros</strong> operates on a foundation of strong values,
          which guide our interactions with both customers and professionals:
        </p>
        <ul className="flex flex-col items-start mt-8 max-w-full leading-8 min-h-[400px] w-[471px]">
          {coreValues.map((value, index) => (
            <ValueItem
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </ul>
      </div>
      <section className="flex flex-col grow shrink items-start self-stretch pb-3.5 my-auto text-lg tracking-tight rounded-none w-[45%]">
        <div className="flex relative flex-col items-end px-11 max-w-full rounded-3xl min-h-[529px] pt-[475px] w-[450px] max-md:pt-24 max-md:pl-5">
          <img loading="lazy" src={whatwd} alt="" className="object-fill rounded-2xl absolute inset-0 size-full" />
          <NotificationCard />
        </div>
      </section>
    </section>
  );
}

function ValueItem({ title, description }) {
  return (
    <li className="flex gap-5 justify-between items-center self-stretch mt-2.5 w-full">
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/98af6430016713d0a6ae749615c5969386495d2810c74d6147e9f4c3731fbba5?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa"
        className="object-contain shrink-0 self-stretch my-auto w-8 aspect-square"
        alt=""
      /> */}
       <i className="object-contain shrink-0 text-[#3B363A] self-stretch my-auto w-8 text-2xl aspect-square fa-solid fa-circle-check"></i>
      <div className="self-stretch my-auto w-[419px]">
        <strong>{title}</strong>: {description}
      </div>
    </li>
  );
}

function NotificationCard() {
  return (
    <div className="flex absolute -bottom-1.5 right-4 z-10 gap-4 px-6 py-6 mr-0 -mb-3.5 bg-white rounded-xl shadow-[0px_4px_9px_rgba(0,0,0,0.05)] max-md:px-5 max-md:mb-2.5">
      <img loading="lazy" src={"https://cdn.builder.io/api/v1/image/assets/TEMP/c4679087d4f40def6949e6957a94a940646dacac450ce01346df497f547ff93d?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa"} alt="" className="object-contain shrink-0 w-6 aspect-square" />
      <div className="basis-auto">We serve you best.</div>
    </div>
  );
}

export default CoreValues;
