import React from 'react';
import logo from '../../assets/img/logo.png'

function Logo() {
  return (
    <img
      loading="lazy"
      src={logo}
      alt="Handypros"
      className="object-contain shrink-0 self-start w-40 max-w-full aspect-[4.1]"
    />
  );
}

export default Logo;