/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";

function Header() {
  const [openBugger, setOpenBugger] = useState(false);
  return (
    <header className="bg-white lg:pb-0">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* <!-- lg+ --> */}
        <nav className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <Link to="/" title="" className="flex">
              <Logo />
            </Link>
          </div>

          <button
            type="button"
            onClick={() => setOpenBugger(!openBugger)}
            className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
          >
            {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
            <svg
              className="block w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 8h16M4 16h16"
              />
            </svg>

            {/* 
                <!-- Menu open: "block", Menu closed: "hidden" --> */}
            <svg
              className="hidden w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            <Link
              to="/"
              title="Home"
              className="text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              {" "}
              Home{" "}
            </Link>

            <a
              href="#about-us"
              title="About Us"
              className="text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              {" "}
              About Us{" "}
            </a>

            <a
              href="#what-we-do"
              title="What we Do"
              className="text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              {" "}
              What We Do{" "}
            </a>

            <a
              href="#faq"
              title="FAQ"
              className="text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              {" "}
              FAQs{" "}
            </a>
            <Link
              to="/contact-us"
              title="Contact Us"
              className="text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
            >
              {" "}
              Contact Us{" "}
            </Link>
          </div>

          <Link
            to="/join-now"
            title=""
            className="items-center justify-center hidden px-4 py-3 ml-10 text-md font-semibold text-white transition-all duration-200 bg-violet-950 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700"
            role="button"
          >
            Join Now
          </Link>
        </nav>

        {openBugger && (
          <nav className="pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden">
            <div className="flow-root">
              <div className="flex flex-col px-6 -my-2 space-y-1">
                <Link
                  to={"/"}
                  title="Home"
                  className="inline-flex py-2 text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Home{" "}
                </Link>

                <a
                  href="#about-us"
                  title="About Us"
                  className="inline-flex py-2 text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  About Us{" "}
                </a>

                <a
                  href="#what-we-do"
                  title="What We Do"
                  className="inline-flex py-2 text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  What We Do{" "}
                </a>

                <a
                  href="#faq"
                  title="FAQs"
                  className="inline-flex py-2 text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  FAQs{" "}
                </a>
                
                <Link
                  to="/contact-us"
                  title="Contact Us"
                  className="inline-flex py-2 text-md font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Contact Us{" "}
                </Link>
              </div>
            </div>

            <div className="px-6 mt-6">
              <Link
                to="/join-now"
                title=""
                className="inline-flex justify-center px-4 py-3 w-full text-md font-semibold text-white transition-all duration-200 bg-violet-950 border border-transparent rounded-md tems-center hover:bg-blue-700 focus:bg-blue-700"
                role="button"
              >
                {" "}
                Join Now{" "}
              </Link>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
