/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const footerColumns = [
  {
    title: "Quick Links",
    items: ["Help centre", "What we do", "About us", "Contact us"],
  },
  {
    title: "Contact Information",
    items: [
      "1, Adeola Odeku Street, Sapetro Tower, 8th Floor. Victoria Island. Lagos",
      "02016320000, 02016320001",
      "09153859962, 09153859965",
      "info@handypros.ng",
    ],
  },
  {
    title: "Working Hours",
    items: ["Monday - Friday 9:00 - 17:00"],
  },
];

function Footer() {
  return (
    <footer className="flex flex-col mt-24 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col justify-center items-center px-20 py-16 w-full bg-neutral-700 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full max-w-[1180px] max-md:max-w-full">
          <div className="flex flex-wrap gap-10 justify-between items-center mx-3.5 w-full max-w-[1154px] max-md:mr-2.5 max-md:max-w-full">
            <div className="flex overflow-hidden flex-col items-start self-stretch my-auto text-lg font-medium text-white min-w-[240px] w-[410px]">
              <h2 className="text-3xl font-semibold">HandyPros</h2>
              <p className="mt-5 leading-loose text-neutral-400">
                Get started now try our product
              </p>
              <EmailSubscription />
              <h3 className="mt-6 text-xl leading-none">Socials</h3>
              <SocialIcons />
            </div>
            <nav className="self-stretch my-auto rounded-none min-w-[240px] w-[587px] max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                {footerColumns.map((column, index) => (
                  <FooterColumn
                    key={index}
                    title={column.title}
                    items={column.items}
                  />
                ))}
              </div>
            </nav>
          </div>
          <div className="flex flex-wrap gap-5 justify-between mt-5 w-full text-base font-medium leading-loose text-white max-md:max-w-full">
            <p className="max-md:max-w-full text-base">
              © 2024 HandyPros ltd. Copyright and rights reserved
            </p>
            <div className="flex gap-6">
              <Link to="/terms-condition" className="grow">
                Terms and Conditions
              </Link>
              <Link to="/privacy-policy" className="basis-auto">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

function SocialIcons() {
  const socialIconData = [
    { icon: "facebook", link: "http://facebook.com/handypros" },
    { icon: "twitter", link: "https://twitter.com/handypros" },
    { icon: "instagram", link: "https://instagram.com/handypros" },
  ];
  return (
    <>
      <div className="flex flex-row items-center justify-evenly w-full">
        {socialIconData.map((item, index) => (
          <a href={item.link} title={item.icon} target="_blank" key={index} rel="noreferrer">
            <i
              className={`object-contain mt-2.5 w-80 max-w-full aspect-[10] fa-brands text-white fa-${item.icon}`}
            ></i>
          </a>
        ))}
      </div>
    </>
  );
}

function FooterColumn({ title, items }) {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow items-start text-base font-medium text-neutral-400 max-md:mt-10">
        <h3 className="text-white">{title}</h3>
        {items.map((item, index) => {
          if (title !== "Contact Information") {
            return (
              <a
                href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                key={index}
                className="mt-5"
              >
                {item}
              </a>
            );
          } else {
            return (
              <a href="#" key={index} className="mt-5">
                {item}
              </a>
            );
          }
        })}
      </div>
    </div>
  );
}

function EmailSubscription() {
  return (
    <form className="flex gap-5 justify-between self-stretch py-2 pr-2 pl-5 mt-9 leading-loose border-2 border-solid border-neutral-400 rounded-[70px] text-neutral-400">
      <label htmlFor="email" className="sr-only">
        Enter your email here
      </label>
      <input
        type="email"
        id="email"
        placeholder="Enter your email here"
        className="bg-transparent border-none outline-none text-neutral-400 w-full"
        aria-label="Enter your email here"
      />
      <button
        type="submit"
        aria-label="Subscribe"
        className="flex shrink-0 rounded-full bg-neutral-700 h-[46px] w-[46px]"
      />
    </form>
  );
}

export default Footer;
