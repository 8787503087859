import React, { useCallback, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import contractor from "../../assets/img/hero/contractor.jpg";
import welder from "../../assets/img/hero/welder.jpg";
import tailor from "../../assets/img/hero/tailor.jpg";
import mecha from "../../assets/img/hero/mecha.jpg";
import { Link } from "react-router-dom";

const slides = [
  {
    image: mecha,
    title: "Join the community of Professionals.",
    description:
      "Let's make your work more organize and easily using the Handypros Dashboard with many of the latest features in managing work everyday.",
  },
  {
    image: welder,
    title: "Streamline Your Workflow",
    description:
      "Handypros's intuitive interface helps you organize tasks, set priorities, and meet deadlines with ease.",
  },
  {
    image: tailor,
    title: "Boost Your Productivity",
    description:
      "Leverage powerful analytics and customizable dashboards to optimize your workflow and increase efficiency.",
  },
  {
    image: contractor,
    title: "Stay Ahead with Cutting-Edge Features",
    description:
      "Experience the latest in task management technology with Handypros's regularly updated feature set.",
  },
];

function HeroSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const nextSlide = useCallback(() => {
    if (slides.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    if (slides.length > 0) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
      );
    }
  }, [slides.length]);

  useEffect(() => {
    let intervalId;
    if (isAutoPlaying && slides.length > 1) {
      intervalId = setInterval(nextSlide, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isAutoPlaying, nextSlide, slides.length]);
  return (
    <>
      <div className="relative w-full mx-auto overflow-hidden rounded-b-md shadow-2xl">
        <div className="relative w-full lg:h-[85vh] h-[75vh]">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ease-in-out py-10 lg:py-16 lg:px-16 px-4 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="flex z-40 flex-wrap gap-4 justify-between items-center text-lg w-full">
                <section className="flex flex-col self-stretch my-auto w-full lg:w-[45%] ">
                  <h1 className="text-6xl font-bold text-white w-full max-md:text-5xl">
                    {slide.title}
                  </h1>
                  <p className="mt-8 font-medium leading-8 text-gray-200 w-full">
                    {slide.description}
                  </p>
                  <div className="flex gap-10 justify-between items-center mt-8 w-10/12">
                    <Link to={"/join-now"}>
                      <Button variant="primary">Join Now</Button>
                    </Link>
                    <Link to={"/contact-us"} className="text-white text-center">
                        Contact Us
                    </Link>
                  </div>
                </section>
                <div className="my-auto aspect-[1.51] w-full lg:w-[50%]" />
              </div>
              <img
                src={slide.image}
                alt={slide.title}
                className="object-cover -z-30 w-full h-full absolute bottom-0 left-0 right-0"
              />
              {/* <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
              <h2 className="text-3xl font-bold mb-2">{slide.title}</h2>
              <p className="text-lg">{slide.description}</p>
              </div> */}
            </div>
          ))}
        </div>
        <div className="absolute -z-10 inset-0 bg-gradient-to-t from-black/90 to-slate-950/30" />

        {slides.length > 1 && (
          <div className="z-10">
            {/* Navigation Arrows */}
            <button
              onClick={prevSlide}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white/45 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <ChevronLeftIcon size={24} />
            </button>
            <button
              onClick={nextSlide}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white/45 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <ChevronRightIcon size={24} />
            </button>

            {/* Progress Indicators */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {slides.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === currentIndex
                      ? "bg-white scale-125"
                      : "bg-white/50 hover:bg-white/80"
                  }`}
                />
              ))}
            </div>

            {/* Auto-play Toggle */}
            <button
              onClick={() => setIsAutoPlaying(!isAutoPlaying)}
              className="absolute top-4 right-4 bg-white/30 text-white px-3 py-1 rounded-full text-sm font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              {isAutoPlaying ? "Pause" : "Play"}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

function Button({ variant = "primary", children, ...props }) {
  const baseClasses = "font-medium";
  const variantClasses = {
    primary: "px-8 py-5 text-white bg-violet-950 rounded-[25px] max-md:px-5",
    text: "px-8 py-5 font-bold text-stone-900",
  };

  return (
    <button className={`${baseClasses} ${variantClasses[variant]}`} {...props}>
      {children}
    </button>
  );
}

export default HeroSection;
