import React from "react";
import whatwd from "../../assets/img/whatwd.png"

const features = [
  {
    text: "Technical proficiency in specific trade skills",
  },
  {
    text: "Customer service and communication skills",
  },
  {
    text: "Service Industry as a driving force for any economy to thrive",
  },
  {
    text: "Professionalism and ethics in service delivery",
  },
  {
    text: "Health and safety practices for on-site work",
  }
];

function EmpowringSection() {
  return (
    <main className="flex flex-wrap-reverse gap-10 justify-center items-center mt-24 max-w-full text-stone-900 w-full max-md:mt-10 py-10 lg:px-16 px-4" data-aos="fade-in">
      <section className="flex flex-col grow shrink items-start self-stretch pb-3.5 my-auto text-lg tracking-tight rounded-none w-[446px] max-md:max-w-full">
        <div className="flex relative flex-col items-end px-11 max-w-full rounded-3xl min-h-[529px] pt-[475px] w-[450px] max-md:pt-24 max-md:pl-5">
          <img loading="lazy" src={whatwd} alt="" className="object-cover absolute inset-0 size-full" />
          <NotificationCard />
        </div>
      </section>
      <section className="flex flex-col grow shrink self-stretch my-auto text-base font-medium w-[469px] max-md:max-w-full">
        <h1 className="text-4xl font-bold max-md:max-w-full">
          Empowering Professionals with Training and Tools
        </h1>
        <p className="mt-2.5 leading-8 max-md:max-w-full">
          HandyPros is a digital marketplace that connects users with professionals across various service sectors. We offer a wide range of bespoke services delivered by qualified and trusted experts, including but not limited to:
        </p>
        <ul className="flex flex-col justify-between items-start mt-6 max-w-full leading-8 min-h-[316px] w-[471px]">
          {features.map((feature, index) => (
            <FeatureItem key={index} text={feature.text} />
          ))}
        </ul>
      </section>
    </main>
  );
}

function NotificationCard() {
    return (
      <div className="flex absolute -bottom-1.5 right-4 z-10 gap-4 px-6 py-6 mr-0 -mb-3.5 bg-white rounded-xl shadow-[0px_4px_9px_rgba(0,0,0,0.05)] max-md:px-5 max-md:mb-2.5">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4679087d4f40def6949e6957a94a940646dacac450ce01346df497f547ff93d?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa" alt="" className="object-contain shrink-0 w-6 aspect-square" />
        <div className="basis-auto">New Request available.</div>
      </div>
    );
  }

  function FeatureItem({ text }) {
    return (
      <li className="flex gap-5 self-stretch w-full mt-5 first:mt-0">        
       <i className="object-contain shrink-0 text-[#3B363A] self-stretch my-auto w-8 text-2xl aspect-square fa-solid fa-circle-check"></i>
        <div className={`self-stretch my-auto`}>{text}</div>
      </li>
    );
  }

export default EmpowringSection;