import React, { useState } from "react";

const faqData = [
  {
    question: "What is HandyPros?",
    answer:
      "HandyPros is a Service Technology platform connecting skilled professionals with clients who require various services. We provide training, branding, and visibility for professionals to help grow their business and improve service delivery.",
    isOpen: true,
  },
  {
    question: "How do I register as a professional on HandyPros?",
    answer: "To register, click on the “Join as a Professional” option on the HandyPros app or website. You will need to complete the registration form, pay a registration fee of ₦35,000, which covers criminal record checks and address verification, and submit necessary documents for verification.",
  },
  {
    question: "What are the benefits of joining HandyPros as a professional?",
    answer: "Professionals on HandyPros benefit from: \n• Access to clients in real time \n• Free training to improve service delivery and professionalism \n• Provision of branded kits and tools \n• Visibility and recognition in your field \n• Increased potential earnings",
  },
  {
    question: "What is the registration fee, and what does it cover?",
    answer: "The registration fee is ₦35,000. This fee covers criminal record verification, address verification, and access to HandyPros training, branding support and administrative fee.",
  },
  {
    question: "What documents are required during the onboarding process?",
    answer: "You’ll need to provide the following documents: \n• A valid ID (National ID, International Passport, or Driver’s License) \n• Proof of address (utility bill, rent agreement, etc.) not less than 3 months \n• Criminal record check certificate (facilitated by HandyPros) \n• Professional certification (if applicable) \n• Passport Photograph \n• NIN \n• CAC Certificate (optional) \n• Educational Certificate (BSc, HND, OND, NABTECH, SSCE \n• 2 Guarantors (1 family member & Religious Leader) \n• License (where applicable",
  },
  {
    question: "How can I ensure that my services are visible on the HandyPros app?",
    answer: "Once your profile is verified and you have completed the training and branding process, your services will automatically be visible on the HandyPros app to clients in real time.",
  },
  {
    question: "What categories of services does HandyPros offer for professionals?",
    answer: "HandyPros covers a wide range of services including plumbing, electrical work, carpentry, cleaning, driving, babysitting, chef services, therapist, masseuse, auto mechanics carer, general practitioners, towing vehicle, ambulance and paramedics and many more. You will be classified into the category that suits your skills during the onboarding process.",
  },
  {
    question: "How does payment work on HandyPros?",
    answer: "Clients can make payments directly through the HandyPros in app payment platform. Professionals receive payment into an escrow account once the customer accept quote that will be shared on the app chat while the user releases the fund after the service has been satisfactorily completed.",
  },
  {
    question: "How do I resolve disputes or complaints from users?",
    answer: "HandyPros provides a dispute resolution mechanism. If a dispute arises, contact HandyPros customer service that is open 24/7 on 02016320000, 02013300008, and we will mediate to resolve the issue between you and the client.",
  },
];

function FAQSection() {
  return (
    <section className="flex flex-col mt-24 max-w-full rounded-xl w-[1004px] max-md:mt-10 lg:px-16 px-4">
      <h2 className="self-center text-5xl font-semibold text-center text-neutral-700 max-md:max-w-full max-md:text-4xl">
        Frequently Asked Questions
      </h2>
      <div className="shrink-0 self-center mt-7 border-solid border-[5px] border-stone-900 h-[5px] w-[106px]" />
      <p className="self-center mt-11 text-xl leading-none text-center text-black text-opacity-80 max-md:mt-10 max-md:max-w-full">
        Here are some frequently asked questions users ask when using HandyPros.
      </p>
      {faqData.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={item.isOpen}
        />
      ))}
    </section>
  );
}

function FAQItem({ question, answer, isOpen }) {
  const [isOpened, setIsOpened] = useState(isOpen);
  return (
    <div
      className={`flex flex-col cursor-pointer px-5 mt-5 w-full text-xl font-semibold leading-none bg-violet-50 rounded-xl min-h-[86px] text-neutral-700 max-md:max-w-full ${
        isOpened ? "p-5" : ""
      }`}
      onClick={()=> setIsOpened(!isOpened)}
    >
      <div className="flex relative gap-10 items-start py-7 max-w-full min-h-[86px] w-[964px]">
        <div className="z-0 my-auto max-md:max-w-full">{question}</div>
        <img
          loading="lazy"
          src={
            isOpened
              ? "https://cdn.builder.io/api/v1/image/assets/TEMP/bf232024d671dfed8e0bc676e4e3e042902b8010cca2bf619c4b0682d7371400?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa"
              : "https://cdn.builder.io/api/v1/image/assets/TEMP/ad0a6fa13cb74a2d41ed1690133c7189df8aa4421babed64f6063398aa84ce68?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa"
          }
          alt=""
          className="object-contain absolute right-0 bottom-2.5 z-0 shrink-0 self-start aspect-square h-[66px] w-[66px]"
        />
      </div>
      {isOpened && (
        <div className="text-sm text-gray-800 max-md:max-w-full font-medium">
          {answer}
        </div>
      )}
    </div>
  );
}

export default FAQSection;
