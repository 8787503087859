import React from "react";
import whatwd from "../../assets/img/whatwd.png"

const services = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4f15795d9d01dcda2b6271b625c32d119e313f63761d5d8b9e142779f723e99?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
    description: "Home repairs (plumbing, electrical work, carpentry, etc.)"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4f15795d9d01dcda2b6271b625c32d119e313f63761d5d8b9e142779f723e99?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
    description: "Domestic services (nannies, cleaners, chefs, drivers)"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4f15795d9d01dcda2b6271b625c32d119e313f63761d5d8b9e142779f723e99?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
    description: "Personal care (fitness trainers, beauty specialists)"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4f15795d9d01dcda2b6271b625c32d119e313f63761d5d8b9e142779f723e99?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
    description: "Technical and industrial services"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4f15795d9d01dcda2b6271b625c32d119e313f63761d5d8b9e142779f723e99?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
    description: "Business support services (IT experts, office management)etc"
  }
];

function WhatWeDo() {
  return (
    <section className="flex flex-wrap gap-10 justify-between items-center mt-24 max-w-full w-full max-md:mt-10  py-10 lg:px-16 px-4" id="what-we-do" data-aos="fade-in">
      <div className="flex flex-col items-start self-stretch my-auto text-base font-medium text-stone-900 w-full lg:w-[53%]">
        <h2 className="text-5xl font-bold max-md:text-4xl">What we do</h2>
        <p className="self-stretch leading-8">
          HandyPros is a digital marketplace that connects users with professionals across various service sectors. We offer a wide range of bespoke services delivered by qualified and trusted experts, including but not limited to:
        </p>
        <ul className="flex flex-col justify-between items-start mt-6 max-w-full leading-8 min-h-[316px] w-[90%]">
          {services.map((service, index) => (
            <ServiceItem key={index} icon={service.icon} description={service.description} />
          ))}
        </ul>
      </div>
      <section className="flex flex-col grow shrink items-start self-stretch pb-3.5 my-auto text-lg tracking-tight rounded-none w-full lg:w-[446px]">
        <div className="flex relative flex-col items-end px-11 max-w-full rounded-3xl min-h-[529px] pt-[475px] w-[450px] max-md:pt-24 max-md:pl-5">
          <img loading="lazy" src={whatwd} alt="" className="object-cover absolute inset-0 size-full" />
          <NotificationCard />
        </div>
      </section>
    </section>
  );
}

function ServiceItem({ icon, description }) {
    return (
      <li className="flex gap-5 self-stretch w-full mt-5 first:mt-0">
        {/* <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 my-auto w-8 aspect-square" /> */}        
       <i className="object-contain shrink-0 text-[#3B363A] self-stretch my-auto w-8 text-2xl aspect-square fa-solid fa-circle-check"></i>
        <p className="flex-auto">{description}</p>
      </li>
    );
  }

  function NotificationCard() {
    return (
      <div className="flex absolute -bottom-1.5 right-4 z-10 gap-4 px-6 py-6 mr-0 -mb-3.5 bg-white rounded-xl shadow-[0px_4px_9px_rgba(0,0,0,0.05)] max-md:px-5 max-md:mb-2.5">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4679087d4f40def6949e6957a94a940646dacac450ce01346df497f547ff93d?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa" alt="" className="object-contain shrink-0 w-6 aspect-square" />
        <div className="basis-auto">New Request available.</div>
      </div>
    );
  }

export default WhatWeDo;