import React from "react";
import vision from '../../assets/img/vision.png'
import mision from '../../assets/img/mission.png'
import future from '../../assets/img/future.png'

const ratingData = [
  {
    rating: 4.9,
    company: "databricks",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/dc0eebd63088fb8ee0254dd1c23500427f940c896aab8cdeba0486b77d66f942?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
  },
  {
    rating: 4.8,
    company: "Chainalysis",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/34d3fa2c01eb2b6d96f0d7fba083fd74ebf91a8b7bbc3e9e590f4ef60170c045?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa",
  },
];

const cardData = [
  {
    title: "Our Vision",
    content:
      "To be the Pioneer ServiTech Company in Nigeria, Africa, connecting users with trained professionals for domestic maintenance and professional services, while fostering a culture of excellence, professionalism, and mutual respect among service providers and users.",
    imageSrc: vision
  },
  {
    title: "Our Mission",
    content:
      "To revolutionize the perception of craftsmen, elevate their status, and empower them with opportunities for sustainable income and growth, thereby creating a thriving community of skilled professionals valued and respected in Nigerian society and beyond.",
    imageSrc: mision
  },
  {
    title: "The Future of Service Delivery",
    content:
      "to redefine the service industry across Nigeria and frica at large. We are committed to leveraging technology to provide solutions that benefit both service providers and consumers alike.",
    imageSrc: future
  },
];

function AboutSection() {
  return (
    <section className="flex flex-col mt-24 w-full max-md:mt-10 py-4" id="about-us" data-aos="slide-up">
      <div className="flex flex-col justify-center items-center  py-10 lg:px-16 px-4 w-full bg-slate-50 max-md:px-5">
        <div className="flex flex-wrap gap-4 justify-between items-center text-lg w-full">
          <div className="flex flex-col self-stretch my-auto font-bold w-full lg:w-[50%]">
            <h1 className="text-2xl sm:text-2xl lg:text-4xl font-semibold text-stone-900 w-full">
              Revolutionizing the Service Industry through Innovation,
              Technology, and Professionalism.
            </h1>
            <p className="mt-5 text-base leading-7 text-stone-600 w-full">
              We are redefining the way services are delivered, accessed, and
              experienced across Nigeria and Africa. Founded with a deep
              commitment to bridging the gap between skilled professionals and
              individuals or businesses that need their services, HandyPros is
              more than just a service link hub—it's a comprehensive ecosystem
              designed to empower professionals, craftsmen, and skilled workers
              while ensuring that users receive high-quality, reliable and
              professional services with ease and confidence.
            </p>
            <div className="flex gap-5 justify-between mt-5 max-w-full text-lg w-full">
              {ratingData.map((data, index) => (
                <RatingCard key={index} {...data} />
              ))}
            </div>
          </div>
          <div className="flex flex-col self-stretch my-auto w-full lg:w-[45%]">
            {cardData.map((card, index) => (
              <VisionMissionCard key={index} {...card} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function RatingCard({ rating, company, imageSrc }) {
  return (
    <div className="flex flex-col items-center max-w-full w-full">
      <img
        loading="lazy"
        src={imageSrc}
        alt={`${company} logo`}
        className="object-contain items-start self-stretch w-full h-[30px] aspect-[5.46]"
      />
      <div className="mt-5 text-stone-900">
        {rating}
        <span> / </span>
        <span className="font-medium">5 rating</span>
      </div>
      <div className="mt-5 text-stone-600">{company}</div>
    </div>
  );
}

function VisionMissionCard({ title, content, imageSrc }) {
  return (
    <div className="flex flex-row gap-5 justify-between items-start mt-5 w-full">
      <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-contain shrink-0 aspect-square w-[50px]"
      />
      <div className="flex flex-col rounded-none">
        <h2 className="self-start text-lg lg:text-2xl font-bold text-stone-900">
          {title}
        </h2>
        <p className="mt-2.5 text-base font-medium leading-6 text-stone-600">
          {content}
        </p>
      </div>
    </div>
  );
}
export default AboutSection;
