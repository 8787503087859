import React from "react";

const logoData = [
  { name: "Unsplash", imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/044170458d56e1b54954795361e9ce937dab29929998748618f2c33a103b38ce?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa", imageClass: "object-contain shrink-0 self-start aspect-[1.06] w-[25px]" },
  { name: "Notion", imageSrc: "", imageClass: "flex shrink-0 self-start bg-neutral-400 h-[20px] w-[25px]" },
  { name: "INTERCOM", imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e8369c10eefeb98b4927140185bb172fcb897ff7d31952314473e0140074c7b6?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa", imageClass: "object-contain shrink-0 my-auto rounded-none aspect-square w-[25px]" },
  { name: "descript", imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7a16b48d9ca9a8825c224c79881b7804c8a82e5e5f5adb859d4027f0e1cc10f?placeholderIfAbsent=true&apiKey=5fa524da69784606825bdce1461219fa", imageClass: "object-contain shrink-0 my-auto aspect-[0.77] w-[25px]" },
  { name: "grammarly", imageSrc: "", imageClass: "flex shrink-0 my-auto rounded-full bg-neutral-400 h-[20px] w-[25px]" }
];

function TrustedPartners() {
  return (
    <section className="flex flex-col items-center justify-center mt-24 max-w-full rounded-none w-[95%] max-md:mt-10" data-aos="flip-up">
        <section className="py-10 sm:py-16 lg:py-16 w-full">
          <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
              <div className="max-w-2xl mx-auto text-center">
                  <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Our achievments</h2>
                  <p className="mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                    we've helped as many as we can, check out our record.
                  </p>
              </div>

              <div className="grid grid-cols-1 gap-8 mt-10 text-center lg:mt-24 sm:gap-x-8 md:grid-cols-3">
                  <div>
                      <h3 className="font-bold text-7xl">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 169 </span>
                      </h3>
                      <p className="mt-4 text-xl font-medium text-gray-900">Services</p>
                      <p className="text-base mt-0.5 text-gray-500">Contact us today for our services</p>
                  </div>

                  <div>
                      <h3 className="font-bold text-7xl">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 3237 </span>
                      </h3>
                      <p className="mt-4 text-xl font-medium text-gray-900">Jobs Done</p>
                      <p className="text-base mt-0.5 text-gray-500">Get your projects/product done in a jiffy</p>
                  </div>

                  <div>
                      <h3 className="font-bold text-7xl">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 803 </span>
                      </h3>
                      <p className="mt-4 text-xl font-medium text-gray-900">Professionals</p>
                      <p className="text-base mt-0.5 text-gray-500">Working for your success</p>
                  </div>
              </div>
          </div>
      </section>

      {/* <h2 className="self-center text-2xl lg:text-3xl font-bold text-center text-stone-900 max-md:max-w-full">
        More than 25,000 teams use Collabs
      </h2>
      <div className="flex flex-wrap gap-3 justify-between items-start mt-16 w-full text-lg font-semibold whitespace-nowrap text-neutral-400 max-md:mt-10 max-md:max-w-full">
        {logoData.map((logo, index) => (
          <LogoItem key={index} {...logo} />
        ))}
      </div> */}
    </section>
  );
}

function LogoItem({ name, imageSrc, imageClass }) {
    return (
      <div className="flex gap-2.5 self-stretch text-center">
        
        {imageSrc ? (
          <img loading="lazy" src={imageSrc} alt={`${name} logo`} className={imageClass} />
        ) : (
          <div className={imageClass} aria-hidden="true" />
        )}
        <div className="basis-auto">{name}</div>
      </div>
    );
}

export default TrustedPartners;