import React from "react";

const PrivacySection = () => {
  return (
    <>
      <div className="container mx-auto my-10 bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-4xl font-bold text-center text-primary border-b-2 border-primary pb-4 mb-8">
          HandyPros App Privacy Policy
        </h1>

        <p className="text-xl text-center text-gray-600 italic mb-12">
          Last updated: 2023
        </p>

        <div className="space-y-8">
          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              1. Information We Collect
            </h2>
            <p>
              We collect the following types of information to provide and
              improve our services:
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              1.1 Personal Information
            </h3>
            <ul className="list-disc list-inside mt-2">
              <li>
                Account Information: When you register, we collect information
                such as your name, email address, phone number, and payment
                information.
              </li>
              <li>
                Professional Information: Professionals providing services
                through the HandyPros platform must provide details like
                certifications, skills, service history, and identification
                documents.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              1.2 Non-Personal Information
            </h3>
            <ul className="list-disc list-inside mt-2">
              <li>
                Device Information: We collect data about the device you use to
                access the App, including IP address, browser type, device
                model, operating system, and unique device identifiers.
              </li>
              <li>
                Usage Information: Information about how you interact with the
                App, including the features you use, pages visited, and service
                requests.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              1.3 Location Information
            </h3>
            <p>
              With your consent, we collect your location data to help match you
              with nearby professionals or services. Location data is also used
              for safety and fraud prevention.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              1.4 Payment Information
            </h3>
            <p>
              The payment gateway collects your payment card details, including
              card number and billing information, to facilitate transactions.
              Payment processing is done through secure, third-party providers.
              Please note that HandyPros has nothing to do with your Bank
              account and Card details.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              1.5 Communications
            </h3>
            <p>
              We may collect and store any communications you make through the
              App (e.g., messages between you and HandyPros support) for quality
              assurance and service improvement purposes.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              2. How We Use Your Information
            </h2>
            <p>We use the information we collect for the following purposes:</p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              2.1 To Provide Services
            </h3>
            <ul className="list-disc list-inside mt-2">
              <li>
                Matching Customers with Professionals, scheduling appointments,
                and managing transactions through the App.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              2.2 To Process Payments
            </h3>
            <p>
              Payment details are used to facilitate transactions between
              Customers and Professionals and for subscription payments from
              Professionals.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              2.3 To Improve the App
            </h3>
            <p>
              Monitoring usage patterns, analyzing trends, and enhancing
              features to improve user experience.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              2.4 To Ensure Safety and Compliance
            </h3>
            <p>
              Conducting background checks for Professionals, verifying
              identities, and ensuring compliance with our policies and
              applicable law.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              2.5 Marketing and Promotions
            </h3>
            <p>
              With your consent, we may send you promotional messages about new
              services, features, and events. You can opt out of these
              communications at any time.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              3. Sharing Your Information
            </h2>
            <p>
              HandyPros does not sell or rent your personal information to third
              parties. We may share your data in the following cases:
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              3.1 Service Providers
            </h3>
            <p>
              We may share your information with third-party service providers
              who perform services on our behalf, such as payment processors,
              background check companies, and customer support services.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              3.2 Legal Obligations
            </h3>
            <p>
              We may disclose your data to comply with legal obligations or in
              response to a lawful request by public authorities, including to
              meet national security or law enforcement requirements.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              3.3 Business Transfers
            </h3>
            <p>
              In the event of a merger, acquisition, or sale of HandyPros Ltd,
              user information may be transferred as part of the business
              transaction, subject to this Privacy Policy.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              3.4 Marketing Partners
            </h3>
            <p>
              With your consent, we may share non-personal, aggregated data with
              partners for marketing and analytics purposes.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              4. Data Security
            </h2>
            <p>
              HandyPros is committed to protecting your personal data. We
              implement a variety of security measures to maintain the safety of
              your information, including encryption, access controls, and
              secure servers. However, no method of transmission over the
              Internet or electronic storage is 100% secure, so we cannot
              guarantee its absolute security.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              5. Data Retention
            </h2>
            <p>
              We retain your personal data for as long as necessary to fulfill
              the purposes outlined in this Privacy Policy or as required by
              law. You may request the deletion of your personal data at any
              time by contacting us, and we will delete your data unless we are
              required to retain it for legal or operational purposes.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              6. Your Rights and Choices
            </h2>
            <p>
              You have certain rights regarding your personal data, including:
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              6.1 Access and Correction
            </h3>
            <p>
              You can access, update, or correct your personal data by logging
              into your account or contacting us directly.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              6.2 Data Portability
            </h3>
            <p>
              You may request a copy of your personal data in a structured,
              machine-readable format.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              6.3 Opt-Out
            </h3>
            <p>
              You may opt out of receiving promotional emails or marketing
              messages by following the instructions in those communications or
              contacting us.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              6.4 Account Deletion
            </h3>
            <p>
              You may request the deletion of your account and personal data by
              contacting us through the contact information provided below.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              7. Children's Privacy
            </h2>
            <p>
              The HandyPros App is not intended for children under the age of
              18. We do not knowingly collect or solicit personal data from
              children. If we become aware that we have collected data from a
              child under 18, we will delete it immediately.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              8. Third-Party Links
            </h2>
            <p>
              The HandyPros App may contain links to third-party websites or
              services. We are not responsible for the privacy practices of
              these third parties, and we encourage you to review their privacy
              policies before providing them with any personal information.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              9. Changes to This Privacy Policy
            </h2>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or applicable laws. We will notify you of
              any material changes by posting the revised Privacy Policy on the
              App or through email notification. Your continued use of the App
              after such changes constitutes acceptance of the updated Privacy
              Policy.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              10. Contact Us
            </h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              how your personal data is handled, please contact us at:
            </p>
            <ul className="list-disc list-inside mt-2">
              <li>Email: info@handypros.ng</li>
              <li>Phone: 02016320000</li>
              <li>
                Address: 1, Adeola Odeku Street, Sapetro Tower, 8th Floor.
                Victoria Island. Lagos
              </li>
            </ul>
          </div>
        </div>

        <footer className="mt-12 bg-primary text-white p-4 rounded-b-2xl text-center">
          <p>&copy;2023 HandyPros Ltd</p>
        </footer>
      </div>
    </>
  );
};

export default PrivacySection;
