import React from "react";

function CTASection() {
  return (
    <section className="flex flex-wrap px-12 py-12 gap-10 justify-center items-center mt-24 max-w-full bg-white rounded-3xl min-h-[181px] shadow-[12px_18px_54px_rgba(0,0,0,0.11)] w-[1146px] max-md:mt-10" data-aos="fade-up">
      <SubscriptionContent />
      <SubscribeButton />
    </section>
  );
}

function SubscriptionContent() {
  return (
    <div className="flex flex-col justify-center self-stretch my-auto lg:w-1/2 w-full">
      <h2 className="text-2xl font-semibold text-zinc-800">
        Want to Join our community?
      </h2>
      <p className="mt-4 text-base text-zinc-600">Join us today by clicking the Join now button to get started.</p>
    </div>
  );
}

function SubscribeButton() {
  return (
    <button className="grow shrink gap-2.5 self-stretch my-auto text-lg font-semibold text-white rounded-3xl bg-neutral-700 min-h-[57px] min-w-[240px] w-[195px]">
      Join now
    </button>
  );
}

export default CTASection;
