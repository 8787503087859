import React from 'react'
import Header from '../pageComponents/common/Header';
import Footer from '../pageComponents/common/Footer';
import ContactSection from '../pageComponents/common/ContactSection';

const ContactUs = () => {
    return (
      <div className="w-full h-full">
        <Header />
        <div className="w-full h-full gap-8 flex flex-col flex-grow items-center lg:px-16 px-4">
          <ContactSection />
        </div>
        <Footer />
      </div>
    );
}

export default ContactUs