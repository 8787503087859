import React from "react";

const TermsSection = () => {
  return (
    <>
      <div className="container mx-auto my-10 bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-4xl font-bold text-center text-primary border-b-2 border-primary pb-4 mb-8">
          HandyPros App Terms and Conditions
        </h1>

        <p className="text-xl text-center text-gray-600 italic mb-12">
          Last updated: 2023
        </p>

        <div className="space-y-8">
          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              1. Definitions
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                <strong>HandyPros:</strong> Refers to HandyPros Ltd, the owner
                and operator of the App.
              </li>
              <li>
                <strong>User:</strong> Any individual who downloads, accesses,
                or uses the App for the purpose of seeking or providing
                professional services.
              </li>
              <li>
                <strong>Professional:</strong> A service provider registered on
                HandyPros offering specific services, subject to verification
                and subscription.
              </li>
              <li>
                <strong>Customer:</strong> A user who seeks services through the
                App.
              </li>
              <li>
                <strong>Platform:</strong> The HandyPros mobile application and
                website.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              2. Account Registration and Verification
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                Users are required to register an account using accurate and
                complete information.
              </li>
              <li>
                Professionals must undergo a verification process which may
                include providing personal details, certifications, and
                background checks.
              </li>
              <li>
                HandyPros reserves the right to suspend or terminate accounts
                that provide false or misleading information.
              </li>
              <li>Users must be at least 18 years old to use the App.</li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              3. Service Subscription (for Professionals)
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                Professionals are required to pay a monthly subscription fee for
                visibility on the HandyPros App.
              </li>
              <li>Subscription fees are non-refundable.</li>
              <li>
                Non-payment of subscription fees may result in the suspension or
                removal of professional listings.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              4. Service Engagement and Payment
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                HandyPros acts as an intermediary between Professionals and
                Customers.
              </li>
              <li>
                The App facilitates direct transactions between the Customer and
                the Professional. HandyPros is not responsible for the quality
                or outcome of services provided.
              </li>
              <li>
                Customers are encouraged to review and rate Professionals after
                service completion.
              </li>
              <li>
                Payment for services is made through the App's integrated
                payment system. HandyPros may use third-party payment platforms
                to process transactions.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              5. Refund and Cancellation Policy
            </h2>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              Customers:
            </h3>
            <p>
              Cancellations of service requests must be done before the service
              is rendered. Refunds will be processed at HandyPros' discretion
              based on the circumstances of the cancellation.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              Professionals:
            </h3>
            <p>
              Subscription fees are non-refundable, but exceptions may be made
              in specific cases. Any disputes regarding payments must be
              resolved by contacting HandyPros' customer support.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              6. Bank Integration
            </h2>
            <p>
              HandyPros may encourage professionals to open an account with any
              Bank as advised for seamless transactions. However, users are free
              to choose their preferred banking method. HandyPros shall not be
              held liable for any banking issues that arise outside the app's
              platform.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              7. Code of Conduct
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                Users agree to behave respectfully and professionally within the
                App. Abuse, harassment, discrimination, and unethical behavior
                will result in account suspension or termination.
              </li>
              <li>
                Professionals are required to provide accurate service
                descriptions and adhere to the standards set forth by HandyPros.
              </li>
              <li>
                No offline service transaction with Customer and Professional is
                allowed. Any Professional found requesting for such would be
                disengaged without refund. As a user/customer, you are hereby
                advised not to agree or offer offline service request and
                transactions.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              8. Branded Kits and Tools (for Professionals)
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                Upon successful registration, verification and training,
                Professionals may be issued branded kits and tools to represent
                HandyPros.
              </li>
              <li>
                Professionals agree to use the branded materials appropriately
                and in alignment with HandyPros' professional standards.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              9. Liability and Indemnity
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                HandyPros is a platform that connects Professionals and
                Customers and is not liable for any damages, losses, or disputes
                that arise from services provided or received.
              </li>
              <li>
                Users agree to indemnify and hold HandyPros, its officers,
                employees, and agents harmless from any claims arising from
                their use of the platform.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              10. Termination of Use
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                HandyPros reserves the right to terminate or suspend any User's
                access to the App for violations of these Terms or any illegal,
                abusive, or unethical behavior.
              </li>
              <li>
                Upon termination, access to the App will be revoked, and any
                ongoing service arrangements will be discontinued.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              11. Privacy and Data Security
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                HandyPros is committed to ensuring the privacy and security of
                users' data. All personal information is processed in compliance
                with HandyPros' Privacy Policy and applicable data protection
                laws.
              </li>
              <li>
                Users agree that their data may be shared with third parties
                only as necessary to provide the services outlined in the App
                (e.g., payment processors, background check services).
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              12. Intellectual Property
            </h2>
            <ul className="list-disc list-inside mt-2">
              <li>
                All content within the HandyPros App, including but not limited
                to logos, designs, software, text, and media, are the
                intellectual property of HandyPros Ltd.
              </li>
              <li>
                Users are not permitted to reproduce, distribute, or modify any
                aspect of the App without prior written consent from HandyPros.
              </li>
            </ul>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              13. Amendments
            </h2>
            <p>
              HandyPros reserves the right to modify or update these Terms at
              any time. Users will be notified of any material changes via the
              App or email. Continued use of the App after changes have been
              made constitutes acceptance of the updated Terms.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              14. Governing Law
            </h2>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of the Federal Republic of Nigeria. Any disputes arising from
              or in connection with these Terms shall be subject to the
              exclusive jurisdiction of the Nigerian courts.
            </p>
          </div>

          <div className="bg-accent border-l-4 border-secondary rounded-lg p-6 transition duration-300 hover:shadow-md hover:-translate-y-1">
            <h2 className="text-2xl font-semibold text-secondary mb-4">
              15. Contact Information
            </h2>
            <p>
              For any questions, concerns, or support inquiries, please contact
              us at:
            </p>
            <ul className="list-disc list-inside mt-2">
              <li>Email: info@handypros.ng</li>
              <li>Phone: 02016320000</li>
            </ul>
          </div>
        </div>

        <footer className="mt-12 bg-primary text-white p-4 rounded-b-2xl text-center">
          <p>&copy;2023 HandyPros Ltd</p>
        </footer>
      </div>
    </>
  );
};

export default TermsSection;
