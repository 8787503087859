import React from "react";
import ceo from "../../assets/img/teams/ceo.png";
import coo from "../../assets/img/teams/coo.png";
import gm from "../../assets/img/teams/gm.png";
import hrm from "../../assets/img/teams/hrM.png";
import csp1 from "../../assets/img/teams/csp1.png";
import csp2 from "../../assets/img/teams/csp2.png";
import msp from "../../assets/img/teams/msp.png";
import dmp from "../../assets/img/teams/dmp.png";
import tam from "../../assets/img/teams/tam.png";
import tam2 from "../../assets/img/teams/tam2.png";
import paco from "../../assets/img/teams/paco.png";
import bs from "../../assets/img/teams/bs.png";

const OurTeam = () => {
    const teamMembers = [
        {id:1, name: "AMB. FOLORUNSHO EGBEKUNLE", image: ceo, role: "CEO"},
        {id:2, name: "ADELEYE OGUNWOLE", image: coo, role: "COO"},
        {id:3, name: "DAMILOLA DAWODU", image: gm, role: "GENERAL MANAGER"},
        {id:4, name: "LOUIS ODUCHUKWU", image: hrm, role: "HR MANAGER"},
        {id:5, name: "BUKOLA OLORUNFEMI", image: csp1, role: "CUSTOMER SERVICE PROFESSIONAL"},
        {id:6, name: "SUSAN WUOBI", image: csp2, role: "CUSTOMER SERVICE PROFESSIONAL"},
        {id:7, name: "JOHN ALLAO ABIMBOLA", image: msp, role: "MARKETING & SALES PROFESSIONAL"},
        {id:8, name: "NAOMI UGBIEDAH", image: dmp, role: "DIGITAL MARKETING PROFESSIONAL"},
        {id:9, name: "CHIMENE OZURU", image: tam, role: "TECH ADMIN MANAGER"},
        {id:10, name: "KINGSLEY EMEKA", image: tam2, role: "TECH ADMIN MANAGER"},
        {id:11, name: "LEMUEL ILOW-GEORGE", image: paco, role: "PERSONAL ASSIATANT, CEO"},
        {id:12, name: "ABAYOMI APATA", image: bs, role: "BUSINESS STRATEGIST"},
    ]
  return (
    <>
      <section class="py-10 bg-white sm:py-16 lg:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-3xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              Brains who made it successful
            </h2>
            <p class="max-w-2xl mx-auto mt-4 text-xl text-gray-600">
                Get to know our team members
            </p>
          </div>

          <div class="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-3 md:mt-16 lg:gap-x-12">
            {teamMembers && teamMembers.map((item, index)=>(
                <div key={index}>
                    <img
                        class="w-full"
                        src={item.image}
                        alt={item.id}
                    />
                    <p className="mt-8 text-lg font-semibold leading-tight text-black">
                       {item.name}
                    </p>
                    <p className="mt-1 text-base leading-tight text-gray-600">
                        {item.role}
                    </p>
                </div>
            ))}
          </div>

          <div class="mt-8 text-center md:mt-16">
            {/* <a
              href="#"
              title=""
              class="inline-flex items-center justify-center py-4 font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md px-14 hover:bg-blue-700 focus:bg-blue-700"
              role="button"
            >
              {" "}
              Join our team{" "}
            </a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeam;
