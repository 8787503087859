import React from 'react'
import Header from '../pageComponents/common/Header'
import Footer from '../pageComponents/common/Footer'

const JoinNow = () => {
  return (
    <>
        
    <div className="w-full h-full">
      <Header />
      <div className="w-full h-full gap-8 flex flex-col flex-grow items-center lg:px-16 px-4 py-10">
        <h1 className="text-4xl font-bold text-center text-primary border-b-2 border-primary pb-4 mb-2">
        Handy Pros Professional Registration Form
        </h1>
        <iframe
            src={"https://docs.google.com/forms/d/e/1FAIpQLSfy0hEgckx4mLsYbpv6M51hQoIEc4J1KmTMSCCNPqR7rlC3cw/viewform"}
            width={'100%'}
            title='Handy Pros Professional Registration Form'
            height={"700px"}
            className="w-full"
        >
            Loading...
        </iframe>
      </div>
      <Footer />
    </div>
    </>
  )
}

export default JoinNow